import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { generarNumeroAleatorioUnico } from "../../../utils/tools";
import { AlertConfirm, AlertSuccess } from "../../../components/alert";
import DataGridForm from "../../../components/common/DataGridForm";
import ModalEspecialista from "../components/ModalEspecialista";
import { especialistasAction } from "../../../redux/actions";

const EquipoSubespecialidad = ({ formik }) => {
  const dispatch = useDispatch();
  
  const especialistas = [...useSelector((state) => state.especialistas)];
  
  //const subespecialidad = useSelector((state) => state.subespecialidad);

  
    especialistas.forEach(especialista=>{
      let espeacilista_subespecialidad=formik.values["especialistas_subespecialidades"]?.find(especialistaSub=>{
        return especialistaSub.id_especializacion==especialista.id;
      })
  
      if(!espeacilista_subespecialidad)
      {
        const especialistas_subespecialidad={...especialista,id_especializacion:especialista.id};
        delete especialistas_subespecialidad.id;
        const especialistasSub=[...(formik.values["especialistas_subespecialidades"] ?? []),especialistas_subespecialidad];
        formik.setFieldValue("especialistas_subespecialidades",especialistasSub,true) 
      }
    })
  
  

  // console.log("SUBESPECIALIDAD",subespecialidad)
  const [showEspecialista, setShowEspecialista] = useState(false);

  const [showEquipo, setShowEquipo] = useState(true);
  const [especialistaUpd, setEspecialistaUpd] = useState();



  const populateModal = (data) => {
    setShowEspecialista(true);
    setEspecialistaUpd(data);
  };

  const openModalNew = () => {
    setShowEspecialista(true);
    setEspecialistaUpd();
  };

  const handlerOnOffSub = (id, isChecked) => {
    
    
    const updatedItems = formik.values["especialistas_subespecialidades"]?.map((fila) => {
      if (fila.id === id) {
        return { ...fila, pertenece: isChecked };
      } 

      return fila;
    });

    // const itemFound = especialistas.find(fila=>{
    //   if (fila.id === id) {
    //     return true
    //   }

    //   return false;
    // })

    // // console.log("ESPECIALISTAS",formik.values)

    // let especialistasPrevios=formik.values["especialistas_subespecialidades"]??[]
    // if(isChecked)
    // {      
    //   formik.setFieldValue("especialistas_subespecialidades",[...especialistasPrevios,itemFound],true) 
    // }else
    // {
    //   especialistasPrevios=especialistasPrevios.filter(fila=>{
    //     return fila.id!=id
    //   })
    //   formik.setFieldValue("especialistas_subespecialidades",[...especialistasPrevios],true) 
    // }

    // console.log("IUPDATEDITEMS",updatedItems)    
    formik.setFieldValue("especialistas_subespecialidades",[...updatedItems],true)

    //dispatch(especialistasAction([...updatedItems]));
  };

  

  const onReorderEquipo = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...especialistas];
    const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newData.indexOf(e.itemData);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    newData.forEach((item, i) => {
      item.order = i + 1;
    });

    // dispatch(especialistasAction([...newData]));
  };

  const OnOffSub = (data) => {
    if (!data.id) {
      data.id = generarNumeroAleatorioUnico();
    }
    return (
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          checked={data?.pertenece}
          class="custom-control-input"
          onChange={(v) => handlerOnOffSub(data.id, v.target.checked)}
          id={"csPublicadoSub" + data.id}
        />
        <label
          role="button"
          class="custom-control-label"
          for={"csPublicadoSub" + data.id}
        ></label>
      </div>
    );
  };


 

  return (
    <>
     
      <div>
        
          <div>          
            <Row>
              <Col>
                <Form.Group>
                  <label>Especialistas</label>
                  {formik.values["especialistas_subespecialidades"]?.length > 0 ? (
                    <DataGridForm
                      dataSource={formik.values["especialistas_subespecialidades"]??[]}
                      keyExpr="id_especializacion"
                      allowReordering={true}
                      columnHidingEnabled={true}
                      onReorder={onReorderEquipo}
                      columns={[
                        {
                          caption: "Nombre completo",
                          dataField: "nombres",
                        },
                        {
                          caption: "Puesto",
                          dataField: "puesto",
                        },
                        {
                          caption: "Especialización",
                          dataField: "especializacion",
                        },
                        {
                          caption: "Pertenece a esta subespecialidad",
                          alignment: "center",
                          render: "OnOffSub",
                          cellRender: { OnOffSub },
                        },                        
                      ]}
                    />
                  ) : (
                    <h5>
                      Aún no se encuentran especialistas.
                    </h5>
                  )}
                </Form.Group>
              </Col>
            </Row>          
          </div>
        
      </div>      
    </>
  );
};

export default EquipoSubespecialidad;
