import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { generarNumeroAleatorioUnico } from "../../../utils/tools";
import { AlertConfirm, AlertSuccess } from "../../../components/alert";
import DataGridForm from "../../../components/common/DataGridForm";
import ModalEspecialista from "../components/ModalEspecialista";
import { especialistasAction, subespecialidadAction } from "../../../redux/actions";

const Equipo = ({ formik }) => {
  const dispatch = useDispatch();
  const especialistas = useSelector((state) => state.especialistas);
  const subespecialidad = useSelector((state) => state.subespecialidad);
  const [showEspecialista, setShowEspecialista] = useState(false);

  const [showEquipo, setShowEquipo] = useState(false);
  const [especialistaUpd, setEspecialistaUpd] = useState();

  useEffect(() => {
    setShowEquipo(formik.values.show_equipo);
  }, [formik]);

  const populateModal = (data) => {
    setShowEspecialista(true);
    setEspecialistaUpd(data);
  };

  const openModalNew = () => {
    setShowEspecialista(true);
    setEspecialistaUpd();
  };

  const handlerOnOff = (id, isChecked) => {
    const updatedItems = especialistas.map((fila) => {
      if (fila.id === id) {
        return { ...fila, destacado: isChecked };
      } else {
        return { ...fila, destacado: false };
      }
    });

    dispatch(especialistasAction([...updatedItems]));
  };

  const onReorderEquipo = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...especialistas];
    const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newData.indexOf(e.itemData);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    newData.forEach((item, i) => {
      item.order = i + 1;
    });

    dispatch(especialistasAction([...newData]));
  };

  const OnOff = (data) => {
    if (!data.id) {
      data.id = generarNumeroAleatorioUnico();
    }
    return (
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          checked={data.destacado}
          class="custom-control-input"
          onChange={(v) => handlerOnOff(data.id, v.target.checked)}
          id={"csPublicado" + data.id}
        />
        <label
          role="button"
          class="custom-control-label"
          for={"csPublicado" + data.id}
        ></label>
      </div>
    );
  };

  const deleteEspecialista = async (id) => {
    const updatedItems = especialistas.filter((x) => x.id != id);

    const especialistas_subespecialidades=subespecialidad.map(sub=>{
      sub.especialistas_subespecialidades=sub.especialistas_subespecialidades.filter(esp=>{
          return esp.id_especializacion!=id
      })

      return sub;
    })
   
    dispatch(especialistasAction([...updatedItems]));
    dispatch(subespecialidadAction([...especialistas_subespecialidades]));
    AlertSuccess("Especialista eliminado correctamente");
  };

  const RenderAlert = (title, data) => {
    AlertConfirm(
      title,
      'Para eliminarlo definitivamente o recuperarlo deberás ir a la sección "Por eliminar".',
      async (response) => {
        if (response.isConfirmed) {
          deleteEspecialista(data.id);
        }
      }
    );
  };

  const Accion = (data) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-sm btn btn-success"
          onClick={() => {
            populateModal(data);
          }}
        >
          <i className="fa fa-pencil" />
        </button>{" "}
        <button
          type="button"
          className="btn btn-sm btn btn-danger"
          onClick={() => {
            RenderAlert("Está apunto de eliminar este contenido", data);
          }}
        >
          <i className="fa fa-trash" />
        </button>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group
            className={`d-flex align-items-center ${!showEquipo ? "mb-0" : ""}`}
          >
            <h2 className="mr-3 mb-0">Equipo</h2>
            <Form.Check
              type="switch"
              id="switchEquipo"
              onChange={() => {
                formik.values.show_equipo = !showEquipo;
                setShowEquipo(!showEquipo);
              }}
              checked={showEquipo}
            />
          </Form.Group>
        </Col>
      </Row>
      <div>
        {showEquipo ? (
          <div>
            <Row>
              <Col>
                <Form.Group>
                  <label>Descripción</label>
                  <textarea
                    name="descripcion_equipo"
                    onChange={formik.handleChange}
                    value={formik.values.descripcion_equipo}
                    className="form-control"
                    rows={4}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <label>Especialistas</label>
                  {especialistas.length > 0 ? (
                    <DataGridForm
                      dataSource={especialistas}
                      keyExpr="id"
                      allowReordering={true}
                      columnHidingEnabled={true}
                      onReorder={onReorderEquipo}
                      columns={[
                        {
                          caption: "Nombre completo",
                          dataField: "nombres",
                        },
                        {
                          caption: "Puesto",
                          dataField: "puesto",
                        },
                        {
                          caption: "Especialización",
                          dataField: "especializacion",
                        },
                        {
                          caption: "Destacado",
                          alignment: "center",
                          render: "OnOff",
                          cellRender: { OnOff },
                        },
                        {
                          caption: "Acción",
                          alignment: "center",
                          render: "Accion",
                          cellRender: { Accion },
                          hidingPriority: 0,
                        },
                      ]}
                    />
                  ) : (
                    <h5>
                      Aún no se encuentran especialistas. Para iniciar hacer
                      click en "Añadir item".
                    </h5>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={openModalNew}
                >
                  Añadir Item
                </button>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}
      </div>

      <ModalEspecialista
        showModal={showEspecialista}
        setShowModal={setShowEspecialista}
        especialistaUpd={especialistaUpd}
        setEspecialistaUpd={setEspecialistaUpd}
      />
    </>
  );
};

export default Equipo;
